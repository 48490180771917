import React from "react"
import PropTypes from "prop-types"

const SVG = ({
  style = {},
  width = "100%",
  className = "",
  viewBox = "0 0 34 34",
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <path
      d="M717.708,594.732C717.708,584.501 710.424,576.195 701.452,576.195L598.841,576.195C589.869,576.195 582.585,584.501 582.585,594.732L582.585,654.998C582.585,665.229 589.869,673.535 598.841,673.535L701.452,673.535C710.424,673.535 717.708,665.229 717.708,654.998L717.708,594.732Z"
      fill="#fff"
      fillOpacity="0.41"
      transform="matrix(.19774 .10927 -.09582 .1734 -51.985 -162.692)"
    />
    <path
      d="M717.708,594.732C717.708,584.501 710.424,576.195 701.452,576.195L598.841,576.195C589.869,576.195 582.585,584.501 582.585,594.732L582.585,654.998C582.585,665.229 589.869,673.535 598.841,673.535L701.452,673.535C710.424,673.535 717.708,665.229 717.708,654.998L717.708,594.732Z"
      fill="#fff"
      fillOpacity="0.41"
      transform="matrix(.21929 .05437 -.04767 .1923 -96.076 -138.801)"
    />
    <path
      d="M717.708,594.732C717.708,584.501 710.424,576.195 701.452,576.195L598.841,576.195C589.869,576.195 582.585,584.501 582.585,594.732L582.585,654.998C582.585,665.229 589.869,673.535 598.841,673.535L701.452,673.535C710.424,673.535 717.708,665.229 717.708,654.998L717.708,594.732Z"
      fill="#fff"
      fillOpacity="0.41"
      transform="matrix(.22589 -.00424 .00372 .19809 -132.481 -104.313)"
    />
    <path
      d="M717.708,594.732C717.708,584.501 710.424,576.195 701.452,576.195L598.841,576.195C589.869,576.195 582.585,584.501 582.585,594.732L582.585,654.998C582.585,665.229 589.869,673.535 598.841,673.535L701.452,673.535C710.424,673.535 717.708,665.229 717.708,654.998L717.708,594.732Z"
      fill="#fff"
      fillOpacity="0.41"
      transform="matrix(.2171 -.06256 .05486 .19037 -158.72 -61.577)"
    />
    <path
      d="M717.708,594.732C717.708,584.501 710.424,576.195 701.452,576.195L598.841,576.195C589.869,576.195 582.585,584.501 582.585,594.732L582.585,654.998C582.585,665.229 589.869,673.535 598.841,673.535L701.452,673.535C710.424,673.535 717.708,665.229 717.708,654.998L717.708,594.732Z"
      fill="#fff"
      fillOpacity="0.41"
      transform="matrix(.1935 -.11662 .10226 .16969 -173.003 -13.507)"
    />
    <path
      d="M717.708,594.732C717.708,584.501 710.424,576.195 701.452,576.195L598.841,576.195C589.869,576.195 582.585,584.501 582.585,594.732L582.585,654.998C582.585,665.229 589.869,673.535 598.841,673.535L701.452,673.535C710.424,673.535 717.708,665.229 717.708,654.998L717.708,594.732Z"
      fill="#fff"
      fillOpacity="0.41"
      transform="matrix(.15673 -.16273 .1427 .13744 -174.36 36.621)"
    />
    <path
      d="M717.708,594.732C717.708,584.501 710.424,576.195 701.452,576.195L598.841,576.195C589.869,576.195 582.585,584.501 582.585,594.732L582.585,654.998C582.585,665.229 589.869,673.535 598.841,673.535L701.452,673.535C710.424,673.535 717.708,665.229 717.708,654.998L717.708,594.732Z"
      fill="#fff"
      fillOpacity="0.41"
      transform="matrix(.10927 -.19774 .1734 .09582 -162.694 85.393)"
    />
    <path
      d="M717.708,594.732C717.708,584.501 710.424,576.195 701.452,576.195L598.841,576.195C589.869,576.195 582.585,584.501 582.585,594.732L582.585,654.998C582.585,665.229 589.869,673.535 598.841,673.535L701.452,673.535C710.424,673.535 717.708,665.229 717.708,654.998L717.708,594.732Z"
      fill="#fff"
      fillOpacity="0.41"
      transform="matrix(.05437 -.21929 .1923 .04767 -138.803 129.483)"
    />
    <path
      d="M717.708,594.732C717.708,584.501 710.424,576.195 701.452,576.195L598.841,576.195C589.869,576.195 582.585,584.501 582.585,594.732L582.585,654.998C582.585,665.229 589.869,673.535 598.841,673.535L701.452,673.535C710.424,673.535 717.708,665.229 717.708,654.998L717.708,594.732Z"
      fill="#fff"
      fillOpacity="0.41"
      transform="matrix(.16263 .15683 -.13753 .14261 -3.091 -174.37)"
    />
    <g>
      <path
        d="M0.291,0.01C0.216,0.01 0.157,-0.011 0.114,-0.054C0.07,-0.097 0.047,-0.157 0.044,-0.236L0.043,-0.261C0.043,-0.344 0.065,-0.41 0.109,-0.458C0.152,-0.506 0.213,-0.53 0.29,-0.53C0.369,-0.53 0.431,-0.506 0.474,-0.458C0.517,-0.41 0.538,-0.346 0.538,-0.266L0.538,-0.245C0.538,-0.238 0.536,-0.233 0.531,-0.228C0.526,-0.223 0.52,-0.221 0.513,-0.221L0.181,-0.221L0.181,-0.213C0.182,-0.176 0.193,-0.145 0.212,-0.121C0.231,-0.096 0.257,-0.084 0.29,-0.084C0.328,-0.084 0.359,-0.099 0.383,-0.13C0.389,-0.137 0.394,-0.142 0.397,-0.144C0.4,-0.145 0.406,-0.146 0.414,-0.146L0.502,-0.146C0.508,-0.146 0.513,-0.144 0.518,-0.141C0.522,-0.138 0.524,-0.133 0.524,-0.127C0.524,-0.111 0.515,-0.092 0.496,-0.071C0.476,-0.049 0.449,-0.03 0.414,-0.014C0.379,0.002 0.338,0.01 0.291,0.01ZM0.4,-0.306L0.4,-0.308C0.4,-0.347 0.39,-0.379 0.371,-0.403C0.351,-0.426 0.324,-0.438 0.29,-0.438C0.256,-0.438 0.229,-0.426 0.21,-0.403C0.191,-0.379 0.181,-0.347 0.181,-0.308L0.181,-0.306L0.4,-0.306Z"
        fill="#0f0922"
        fillRule="nonzero"
        transform="matrix(15.80145 .01011 -.01011 15.80145 4.898 22.809)"
      />
      <path
        d="M0.302,0C0.241,0 0.196,-0.016 0.165,-0.048C0.134,-0.079 0.119,-0.126 0.119,-0.187L0.119,-0.413L0.04,-0.413C0.033,-0.413 0.028,-0.415 0.023,-0.42C0.018,-0.425 0.016,-0.43 0.016,-0.437L0.016,-0.496C0.016,-0.503 0.018,-0.508 0.023,-0.513C0.028,-0.518 0.033,-0.52 0.04,-0.52L0.119,-0.52L0.119,-0.686C0.119,-0.693 0.121,-0.699 0.126,-0.704C0.131,-0.708 0.136,-0.71 0.143,-0.71L0.226,-0.71C0.233,-0.71 0.239,-0.708 0.244,-0.704C0.248,-0.699 0.25,-0.693 0.25,-0.686L0.25,-0.52L0.375,-0.52C0.382,-0.52 0.387,-0.518 0.392,-0.513C0.397,-0.508 0.399,-0.503 0.399,-0.496L0.399,-0.437C0.399,-0.43 0.397,-0.425 0.392,-0.42C0.387,-0.415 0.382,-0.413 0.375,-0.413L0.25,-0.413L0.25,-0.198C0.25,-0.169 0.255,-0.148 0.265,-0.133C0.275,-0.118 0.291,-0.111 0.314,-0.111L0.384,-0.111C0.391,-0.111 0.397,-0.109 0.402,-0.105C0.406,-0.1 0.408,-0.094 0.408,-0.087L0.408,-0.024C0.408,-0.017 0.406,-0.012 0.402,-0.007C0.397,-0.002 0.391,0 0.384,0L0.302,0Z"
        fill="#0f0922"
        fillRule="nonzero"
        transform="matrix(15.80145 .01011 -.01011 15.80145 13.684 22.814)"
      />
      <path
        d="M0.146,0C0.139,0 0.134,-0.002 0.129,-0.007C0.124,-0.012 0.122,-0.017 0.122,-0.024L0.122,-0.413L0.041,-0.413C0.034,-0.413 0.029,-0.415 0.024,-0.42C0.019,-0.425 0.017,-0.43 0.017,-0.437L0.017,-0.496C0.017,-0.503 0.019,-0.508 0.024,-0.513C0.029,-0.518 0.034,-0.52 0.041,-0.52L0.122,-0.52L0.122,-0.56C0.122,-0.621 0.138,-0.666 0.171,-0.696C0.204,-0.725 0.253,-0.74 0.319,-0.74L0.388,-0.74C0.395,-0.74 0.4,-0.738 0.405,-0.733C0.41,-0.728 0.412,-0.723 0.412,-0.716L0.412,-0.657C0.412,-0.65 0.41,-0.645 0.405,-0.64C0.4,-0.635 0.395,-0.633 0.388,-0.633L0.324,-0.633C0.298,-0.633 0.28,-0.627 0.269,-0.614C0.258,-0.601 0.253,-0.582 0.253,-0.555L0.253,-0.52L0.378,-0.52C0.385,-0.52 0.39,-0.518 0.395,-0.513C0.4,-0.508 0.402,-0.503 0.402,-0.496L0.402,-0.437C0.402,-0.43 0.4,-0.425 0.395,-0.42C0.39,-0.415 0.385,-0.413 0.378,-0.413L0.253,-0.413L0.253,-0.024C0.253,-0.017 0.251,-0.012 0.247,-0.007C0.242,-0.002 0.236,0 0.229,0L0.146,0Z"
        fill="#0f0922"
        fillRule="nonzero"
        transform="matrix(15.80145 .01011 -.01011 15.80145 20.178 22.818)"
      />
    </g>
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.number,
  className: PropTypes.string,
  viewBox: PropTypes.object,
}

export default SVG
