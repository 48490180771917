import React from "react"
// import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"

import Layout from "../components/layout"
import Logo from "../svg/brand"
import SEO from "../components/seo"
import MEDIA from "../helpers/mediaTemplates"

const Main = styled.div`
  height: 100vh;
  min-height: 500px;
  width: 100%;
  color: #f6f5f4;
`

const Background = styled(BackgroundImage)`
  height: 100%;
  background-position: 80% 80% !important;
  background-size: cover;
`

const Container = styled.div`
  margin: 2%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Top = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Bottom = styled(Top)`
  margin: 20px 0px 50px 0px;
  ${MEDIA.TABLET`
    align-items: flex-start;
    flex-direction: column;
`};
`

const Brand = styled.div`
  display: flex;
  align-items: center;
  h1 {
    margin-left: 16px;
    display: inline;
    font-size: 22px;
  }
`

const Social = styled.a`
  text-decoration: none;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #ceced2;
  }
  svg {
    color: #ceced2;
    margin-left: 18px;
    font-size: 26px;
    transition: all 0.3s ease;
  }
  &:hover {
    svg {
      color: #fff;
    }
  }
  ${MEDIA.TABLET`
    span {
      display: none;
    }
    svg {
      font-size: 24px;
    }
`};
`

const Titles = styled.div`
  text-shadow: 0px 3.33543px 30px rgba(0, 0, 0, 0.4);
  margin: 0px 0px 10px 0px;
  padding-right: 20px;
  max-width: calc(100% - 300px);
  h2 {
    font-size: 36px;
    margin-bottom: 0;
    padding-bottom: 0;
    line-height: 1.3;
  }
  h3 {
    margin-top: 6px;
    font-weight: normal;
    font-size: 15px;
    line-height: 1.6;
  }
  ${MEDIA.TABLET`
    max-width: 100%;
    padding-right: 0px;
`};
  ${MEDIA.MOBILE`
  h2 {
    font-size: 32px;
  }
`}
`

const Button = styled.a`
  outline: none;
  border: none;
  padding: 16px 20px;
  margin: 10px 0px 0px 0px;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  background: #f6f5f4;
  text-decoration: none;
  color: #212024;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.15),
      1px 4px 12px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
    transform: translate3D(0, -1px, 0) scale(1.02);
    background: #fff;
  }
`

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "homeBackground.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      brandLogo: file(relativePath: { eq: "ETF.svg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Home" />
      <Main>
        <Background fluid={data.backgroundImage.childImageSharp.fluid}>
          <Container>
            <Top>
              <Brand>
                <Logo width={50} />
                <h1>edtech founders</h1>
              </Brand>
              <Social
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/edtechfounders"
              >
                <span>@edtechfounders</span>
                <FontAwesomeIcon icon={faTwitter} />
              </Social>
            </Top>
            <Bottom>
              <Titles>
                <h2>Together we can create more impactful EdTech</h2>
                <h3>
                  For Entrepreneurs, Educators, Investors, and anyone with an
                  interest in EdTech
                </h3>
              </Titles>
              <Button
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.google.com/forms/d/e/1FAIpQLSfUHuM-9uy-8Hz-u3RyhhzXpmzL_JZnj7NyQUIbQJmTwXL8kA/viewform?usp=sf_link"
              >
                Join the Community. It’s Free!
              </Button>
            </Bottom>
          </Container>
        </Background>
      </Main>
    </Layout>
  )
}

export default IndexPage
